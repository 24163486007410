<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"> <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b"><el-button type="primary" size="small" @click="addTemplate"
						v-right-code="'Template:Addoredit'">新增</el-button>
				</div>
				<div class="h-b"><el-button type="primary" size="small" @click="saveTemplate"
						v-right-code="'Template:Addoredit'">保存</el-button>
				</div>
			</div>

		</div>

		<div class="form-list">
			<el-form ref="_orderTemplateForm" :model="dataSource">
				<el-form-item>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>模板名称：
					</el-col>
					<el-col :span="6">
						<el-form-item prop="Name">
							<el-input v-model="dataSource.Name" :maxlength="30" :minlength="4"
								placeholder="模板名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="2" class="form-title">描述：</el-col>
					<el-col :span="6">
						<el-form-item>
							<el-input v-model.trim="dataSource.Description" :maxlength="50" placeholder="描述"
								autofocus="autofocus"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>作业类型：
					</el-col>
					<el-col :span="6">
						<el-form-item>
							<el-select v-model="dataSource.ImportExportType" placeholder="请选择"
								@change="onOperatorTypeChange" @visible-change="onOperatorTypeVisibleChange" :disabled="dataSource.TemplateID!=null">
								<el-option v-for="item in operatorType" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>业务类型：
					</el-col>
					<el-col :span="6">
						<el-form-item>
							<DictionarySelect v-model="dataSource.BusinessType" dicTypeCode="ImportExportBusinessTypeCode"
							:disabled="dataSource.TemplateID!=null" 
							@change="onBusinessTypeChange"
							@visible-change="onBusinessTypeVisibleChange"  >
										</DictionarySelect>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>表头开始行：
					</el-col>
					<el-col :span="6">
						<el-form-item>
							<el-input-number v-model="dataSource.TableHeaderSatrtLine" @change="onStartLineNoChange"
								:min="1" :max="20" label="表头开始行"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>表头结束行：
					</el-col>
					<el-col :span="6">
						<el-form-item prop="Mobile">
							<el-input-number v-model="dataSource.TableHeaderEndLine" @change="onEndLineNoChange"
								size="small" :min="1" :max="20" label="表头结束行"></el-input-number>
						</el-form-item>
					</el-col>
				</el-form-item>
				<!-- <el-form-item>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>来源系统：
					</el-col>
					<el-col :span="6">
						<el-form-item>
							<el-select v-model="dataSource.ExternalIdentity" :maxlength="200"
								@change="onExternalIdentity">
								<el-option v-for="item in ExternalSystemLits" :key="item.ExternalIdentity"
									:label="item.ExternalSystemName" :value="item.ExternalIdentity">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="2" class="form-title">
                        来源电商平台：
                    </el-col>
                    <el-col :span="6">
                        <el-form-item>
                            <el-select v-model="dataSource.SourceSystemCode" filterable>
                                <el-option v-for="item in dataSource.SourceSystemNames"
                                            :key="item.StringValue"
                                            :label="item.label"
                                            :value="item.StringValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
				</el-form-item> -->
				<el-form-item>
					<el-col :span="2" class="form-title">
						<span style="color:red;">*</span>上传模板名：
					</el-col>
					<el-col :span="6" class="upload-mode">
						<el-form-item prop="UploadFileName">
							<el-input v-model="dataSource.UploadFileName" disabled :maxlength="20"
								placeholder="上传模板名"></el-input>
							<el-button type="primary" :disabled='!isBill' size="small" style="margin-left:10px;"
								@click="onUploadTemplate">上传模板</el-button>
						</el-form-item>
					</el-col>
				</el-form-item>
			</el-form>
			<transfer ref="templateTransfer" v-show="isBill" :fromTitle="fromTitle" :toTitle="toTitle"
				:mapTitle="mapTitle" :dataSource="dataSource" style="margin-top:20px;"></transfer>
		</div>
	</div>
</template>

<script>
	import Transfer from "../../../../../components/common/transfer/transfer.vue";
	import Dialog from '../../../../../components/common/dialog/dialog.js';
	import upload from '../../../../../components/common/importexport/uploadtemplate.vue';

	export default {

		data() {
			var checkUploadFileName = (rule, value, callback) => {
				if (this.dataSource.ImportExportType !== 2) {
					if (value === '' || !value) callback(new Error('请上传一个模板文件'))
					else {
						callback();
					}
				} else callback();
			};

			var checkName = (rule, value, callback) => {
				if (!value || value === '' || value.realLength() < 6 || value.realLength() > 250)
					callback(new Error('长度在 6 到 250 个字符'))
				else {
					callback();
				}
			};
			return {
				multipleSelection: [],
				isDisabled: false,
				importConfig: {
					visible: false,
					size: 'small',
				},
				isBill: true,
				bussType: false,
				exportType: false,
				loadding: false,
				fromTitle: "外部字段",
				toTitle: "OMS内部字段",
				mapTitle: "映射结果",
				templateCheckRule: {
					Name: [{
							required: true,
							message: '请输入模板名称',
							trigger: 'blur'
						},
						{
							validator: checkName,
							trigger: 'blur'
						}
					],
					UploadFileName: [{
						validator: checkUploadFileName,
						trigger: 'blur'
					}, ]
				},
				ExternalSystemLits: [{
						ExternalIdentity: 'EC_WLYD',
						ExternalSystemName: '平台手工订单'
					},
					{
						ExternalIdentity: 'EC_HS',
						ExternalSystemName: '黄石手工订单'
					}
				],
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
				isSystemLoad: false
			},
			dataSource: {
				FromItems: [],
				ToItems: [],
				Items: []
			},
			operatorType: {},
			businessType: {}
		},
		mounted() {
			this.Event.$on("_orderTemplateForm", () => this.resetForm);
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					if (curVal.TemplateID != oldVal.TemplateID && this.bussType == true) {
						this.bussType = false;
					}
					this.ChakVom();
					this.isDisabled = null != this.dataSource.TemplateID;
					this.fromTitle = this.dataSource.ImportExportType == 1 ? "外部字段" : "OMS内部字段";
					this.toTitle = this.dataSource.ImportExportType == 1 ? "OMS内部字段" : "外部字段";
				},
				deep: true
			}
		},
		created: function() {
			this.Event.$on("clearForm", this.resetForm);
		},
		methods: {
			addTemplate() {
				this.Event.$emit("onAdd");
			},
			resetForm() {
				!this.isPassValidate && this.$refs['_customerContactForm'].resetFields(); //清空表单
			},
			onUploadTemplate() {
				let This = this;
				this.$refs.templateTransfer.clear();
				let com = Dialog.show({
					title: '上传模板',
					component: upload,
					size: 'tiny',
					callback: This.getExcelColumns
				}).then(e => This.getExcelColumns(e));
			},
			onOperatorTypeChange(val) {
				this.fromTitle = (val + '') != '1' ? "OMS内部字段" : "外部字段";
				this.toTitle = (val + '') != '1' ? "外部字段" : "OMS内部字段";
				this.dataSource.ImportExportType = val;
				this.handlerGetOmsFields(val * 1);
				this.handlerGetExcelColumns(val * 1);
				//alert(this.exportType);
				//if (this.exportType) {

				//}
				//this.exportType = true;
			},
			ChakVom: function() {
				this.isBill = true;
				//var type = this.dataSource.BusinessType * 1;
				// if (type == 1 || type == 2 || type == 5 || type == 6 || type == 7) {
				// 	this.isBill = true;
				// } else {
				// 	this.isBill = false;
				// }
			},
			onBusinessTypeChange(val) {
				this.dataSource.BusinessType = val;
				this.handlerGetOmsFields();
				//if (this.bussType) {

				//}
				//this.bussType = true;
			},
			onExternalIdentity: function(value) {
				if (this.dataSource.BusinessType == 5) {
					this.handlerGetOmsFields();
				}
			},
			onImportOk: function(options) {
				console.log(this.$refs.importTemplate.fileUrl);
				console.log(this.$refs.importTemplate.fileName);
				var _this = this;
				this.getExcelColumns(this.$refs.importTemplate.fileUrl, this.$refs.importTemplate.fileName);
			},
			getExcelColumns(args) {
				if (args.type == "ok") {
					this.dataSource.UploadFileName = args.data.uploadFileName;
					this.dataSource.ServerFileName = args.data.serverFileName;
					this.config.isSystemLoad = false;
					this.handlerGetOmsFields();
					this.handlerGetExcelColumns();
				}
			},
			handlerGetOmsFields(val) {
				if (this.config.isSystemLoad) return;
				this.dataSource.Items = [];
				let operatorType = val || this.dataSource.ImportExportType*1;
				this.$ajax.send("omsapi/template/getwmsfields", "post", this.dataSource, (data) => {
					if (operatorType === 1)
						this.dataSource.ToItems = data.Result;
					else
						this.dataSource.FromItems = data.Result;
				}, null, false);
			},
			handlerGetExcelColumns(val) {
				if (this.config.isSystemLoad) return;
				this.dataSource.Items = [];
				let operatorType = val || this.dataSource.ImportExportType*1;
				this.$ajax.send("omsapi/template/getcolumns", "post", this.dataSource, (data) => {
					if (operatorType === 1)
						this.dataSource.FromItems = data.Result;
					else
						this.dataSource.ToItems = data.Result;
				}, null, false);
			},
			isValidMapData() {
				const {
					toTitle
				} = this;
				var isValid = false;
				if (!this.dataSource.Items || this.dataSource.Items.length == 0) {
					if (this.dataSource.BusinessType*1 != 9) {
						this.Utils.messageBox("请选择要映射的字段.", "info");
						isValid = false;
					} else {
						isValid = true;
					}
				} else isValid = true;

				if (isValid) {
					if (this.dataSource.ImportExportType === 1) {
						if (this.dataSource.ToItems && this.dataSource.ToItems.length > 0) {
							this.dataSource.ToItems.forEach(item => {
								if (item.Required) {
									isValid = false;
									this.Utils.messageBox(toTitle + "必须字段未做列映射.", "error");
								}
							});
						}
					}
				}

				return isValid;
			},
			saveTemplate() {
				var _this = this;
				this.$refs["_orderTemplateForm"].validate((valid) => {
					if (valid && !_this.isValidMapData()) return false;
					this.isPassValidate = valid;
					if (valid) {
						var routeName = this.dataSource.TemplateID ===null ? "addoredit" : "addoredit";
						this.$ajax.send("omsapi/template/" + routeName, "post", this.dataSource, (
							data) => {
							this.dataSource.TemplateID === null && (this.dataSource
								.TemplateID = data.Result);
							this.Event.$emit("onRefresh", data.Result);
							this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						return false;
					}
				});
			},
			onStartLineNoChange(val) {
				this.dataSource.TableHeaderSatrtLine = val;
				this.handlerGetExcelColumns();
			},
			onEndLineNoChange(val) {
				this.dataSource.TableHeaderEndLine = val;
				this.handlerGetExcelColumns();
			},
			onOperatorTypeVisibleChange(val) {
				this.config.isSystemLoad = false;
			},
			onBusinessTypeVisibleChange(cal) {
				this.config.isSystemLoad = false;
			}
		},
		components: {
			Transfer,
			upload

		}
	}
</script>

<style scpoed>
	/*.left-align {
        text-align: left !important;
    }

    .el-input-number--small .el-input-number__decrease, .el-input-number--small .el-input-number__increase {
        line-height: 23px !important;
    }

    .el-input-number--small {
        width: 100% !important;
    }*/
</style>