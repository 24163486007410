<template>
    <table >
        <tr>
            <td>
                <trans-container ref="fromRef" :title="fromTitle" :data="dataSource.FromItems" :isRadio="isRadio" :showCheckAll="!isRadio"></trans-container>
            </td>
            <td>
                <trans-container ref="toRef" :title="toTitle" :data="dataSource.ToItems" :isRadio="isRadio"></trans-container>
            </td>
            <td class="td-column-center">
                <p>
                    <el-button type="primary" :disabled="toRightDisable" @click="toRight">&gt;&gt;</el-button>
                </p>
                <p>
                    <el-button type="primary" :disabled="toLeftDisable" @click="toLeft">&lt;&lt;</el-button>
                </p>
            </td>
            <td>
                <trans-container :title="mapTitle" width="300px" :data="dataSource.Items" :showCheckAll="true"></trans-container>
            </td>
        </tr>
    </table>
</template>
<script>
    import TransContainer from "./transfercontainer.vue";
    export default {
        name: "transform",
        data: () => ({
        }),
        props: {
            fromTitle: {
                type: String,
                default: 'A'
            },
            toTitle: {
                type: String,
                default: "B"
            },
            mapTitle: {
                type: String,
                default: "C"
            },
            dataSource: {
                FromItems: [],
                ToItems: [],
                Items: []
            },
        },
        methods: {
            noTempalteToRight(fd, td, md, type) {

                let fromList = fd.filter(item => item.Checked).map(item => {
                    return {
                        ...item,
                        Checked: false
                    };
                });

                fromList.forEach(from => {
                    let map = this.Utils.extend({}, from);
                    map.Type = "Map";
                    map.FromList.push(from);
                    let idArray = [],
                        labelArray = [];

                    idArray.push(from.SortNo);
                    labelArray.push(from.Label);
                    map.ClassTypeList.push(...from.ClassTypeList);
                    map.ToList.push(from);

                    map.SortNo = 0;
                    map.Label = map.Label + ' => ' + labelArray.join(',');
                    md.push(map);
                });

                this.dataSource.FromItems = fd.filter(item => !item.Checked);
                this.dataSource.ToItems = [];
            },
            exchangeToRight(fd, td, md, type) {
                //如果是无模板导出
                if (type === 2 && !this.hasTemplate) return this.noTempalteToRight(fd, td, md, type);

                let from = fd.filter(item => item.Checked).map(item => {
                    return {
                        ...item,
                        Checked: false
                    };
                })[0],
                    to = td.filter(item => item.Checked).map(item => {
                        return {
                            ...item,
                            Checked: false
                        };
                    });
                let map = this.Utils.extend({}, from);
                map.Type = "Map";
                map.FromList.push(from);
                let idArray = [],
                    labelArray = [];

                for (let item of to) {
                    idArray.push(item.SortNo);
                    labelArray.push(item.Label);
                    map.ClassTypeList.push(...item.ClassTypeList);
                    map.ToList.push(item);
                }
                map.SortNo = 0;
                map.FiledName = map.Label;
                map.Label = map.Label + ' => ' + labelArray.join(',');
                md.push(map);
                this.dataSource.FromItems = fd.filter(item => !item.Checked);
                this.dataSource.ToItems = td.filter(item => !item.Checked);
            },
            exchangeToLeft(fd, td, md, type) {
                let maps = md.filter(item => item.Checked);
                for (let map of maps) {
                    fd.push({
                        ...map.FromList[0],
                        Checked: false,
                        ToList: []
                    });

                    if (type === 2 && !this.hasTemplate)
                    {

                    }
                    else
                    {
                        for (let item of map.ToList) {
                            td.push({
                                ...item,
                                Checked: false,
                                ToList: []
                            });
                        }
                    }
                }
                this.dataSource.FromItems = fd.sort((a, b) => a.Label.localeCompare(b.Label));
                this.dataSource.ToItems = td.sort((a, b) => a.Label.localeCompare(b.Label));
                this.dataSource.Items = md.filter(item => !item.Checked);
            },
            // 把选择数据转移到目标（右框）
            toRight() {
                let { dataSource: { FromItems: fromData = [], ToItems: toData = [], Items: mapData = [], ImportExportType } } = this;
                this.exchangeToRight(fromData, toData, mapData, ImportExportType);
            },
            // 把选择数据转回到源（左框）
            toLeft() {
                let { dataSource: { FromItems: fromData = [], ToItems: toData = [], Items: mapData = [], ImportExportType }, $refs: { fromRef, toRef } } = this;
                this.clear();
                this.exchangeToLeft(fromData, toData, mapData, ImportExportType);
            },
            clear() {
                let { $refs: { fromRef, toRef } } = this;
                fromRef.clearRadioValue();
                toRef.clearRadioValue();
            }
        },
        computed: {
            isRadio() {
                return (this.dataSource.ImportExportType == 1 || this.hasTemplate);
            },
            toRightDisable() {
                let { dataSource: { FromItems: fromData = [], ToItems: toData = [], ImportExportType } } = this;
                if (ImportExportType === 2 && !this.hasTemplate && fromData.length > 0 && this.fromRefNum >0) return false;
                return fromData.length === 0 || this.fromRefNum === 0 || toData.length === 0 || this.toRefNum === 0;
            },
            toLeftDisable() {
                let { dataSource: { Items: mapData = [], ImportExportType } } = this;
                if (ImportExportType === 2 && !this.hasTemplate && mapData.length > 0 && this.mapRefNum >0) return false;
                return mapData.length === 0 || this.mapRefNum === 0;
            },
            fromRefNum() {
                let { dataSource: { FromItems: fromData = [] } } = this;
                return fromData.filter(item => item.Checked).length;
            },
            toRefNum() {
                let { dataSource: { ToItems: toData = [] } } = this;
                return toData.filter(item => item.Checked).length;
            },
            mapRefNum() {
                let { dataSource: { Items: mapData = [] } } = this;
                return mapData.filter(item => item.Checked).length;
            },
            hasTemplate() {
                return this.dataSource.UploadFileName && this.dataSource.UploadFileName.length > 0;
            }
        },
        components: {
            TransContainer
        }
    };
</script>
<style scoped>
    p {
        display: block;
        margin: 20px 0px;
    }

    table {
        width: auto;
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        vertical-align: top;
        color: #2c3e50;
    }

    table tr td {
        vertical-align: top;
        padding: 0px 10px;
    }

    .td-column-center {
        vertical-align: middle;
        text-align: center;
    }
</style>