import Vue from 'vue'
import dl from './dialog.vue'
const dialogComponent = Vue.extend(dl);

export default class Dialog {
    constructor() { }

    static show(options) {
        var dialogInstance = new dialogComponent({
            el: document.createElement('div'),
            data() {
                return {
                    ...options
                };
            },
            template: options.template,
            components: {
                child: options.component,
            }
        });
		console.log(dialogInstance)
        document.body.appendChild(dialogInstance.$el);

        return dialogInstance;
    }
}
