<template>
  <dl>
    <dd class="left">
      <span>&nbsp;</span>
    </dd>
    <dd class="right">
      <el-radio v-if="isRadio" v-model="radioValue.value" :label="options.SortNo" class="margin-left-checkbox">
        <span class="is-required" v-if="options && options.Required">*</span>{{options.Label}}
      </el-radio>
      <el-checkbox v-else v-model="options.Checked" class="margin-left-checkbox">{{options.Label}}</el-checkbox>
    </dd>
    <br class="clearfix" />
  </dl>
</template>
<script>
  export default {
    name: "TransItem",
    data: () => ({}),
    props: {
      options: {
        Checked: {
          type: Boolean,
          default: false
        },
        Label: {
          type: String,
          default: ""
        },
        Required: {
          type: Boolean,
          default: false
        },
        SortNo: {
          type: String,
          default: ""
        }
      },
      isRadio: {
        type: Boolean,
        default: false
      },
      radioValue: {
        value: {}
      }
    },
    methods: {}
  };
</script>
<style scoped>
  dl {
    display: block;
    -webkit-margin-before: 3px;
    -webkit-margin-after: 3px;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: normal !important;
  }

  .clearfix {
    clear: both;
  }

  dd {
    margin: 0px;
    padding: 0px;
    float: left;
  }

  .left {
    text-align: right;
    width: 10%;
  }

  .right {
    text-align: left;
    width: 90%;
  }

  .is-required {
    color: red;
    position: relative;
    top: 2px;
  }

  dd.right label {
    display: block;
  }
</style>