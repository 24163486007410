<template>
	<div>
		<el-upload class="upload-demo" ref="upload" :data="postData" :multiple="false" :action="importAction"
			:beforeUpload="beforeAvatarUpload" :on-success="onSuccess" :on-error="onError" drag>
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			<div class="el-upload__tip" slot="tip">*只能上传csv/xls/xlsx文件，且不超过1M</div>
			<div class="el-upload__status_tip" slot="tip" v-html="status"></div>
		</el-upload>
		<!--<el-button type="primary" size="small" @click="test">测试</el-button>-->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				importAction: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?token=" + this.Utils.getToken(),
				fileList: [],
				postData: {
					FileType: 500,
					Type: 1, //类型码:1导入2导出
					BusinessType: 5 //运单为5
				},
				status: ''
			};
		},
		methods: {
			submitUpload() {
				this.$refs.upload.submit();
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			onSuccess(response, file, fileList) {
				this.status = "<span style='color:green;'>上传成功.</span>";
				this.$emit("callback", {
					type: 'ok',
					data: {
						uploadFileName: response.Result.FileName,
						serverFileName: response.Result.Url
					}
				});
			},
			onError(err, file, fileList) {
				var infos = err.message.match(/"OperationDesc":"(.*)"/);
				this.status = "<span style='color:red;'>*文件上传失败！" + (infos && infos.length > 0 ? infos[infos.length - 1] :
					'') + "<span>";
			},
			// 上传前对文件的大小的判断
			beforeAvatarUpload(file) {
				const extension = file.name.split('.')[1] === 'xls'
				const extension2 = file.name.split('.')[1] === 'xlsx'
				const extension3 = file.name.split('.')[1] === 'csv'
				const isLt5M = file.size / 1024 / 1024 < 1
				if (!extension && !extension2 && !extension3) {
					this.status = "<span style='color:red;'>上传模板只能是 xls、xlsx、csv 格式!<span>";
					return false;
				}
				if (!isLt5M) {
					this.status = "<span style='color:red;'>上传模板大小不能超过 1MB!<span>";
					return false;
				}
				return extension || extension2 || extension3 && isLt5M
			},
			test() {
				this.$emit("callback", {
					type: "ok"
				});
			}
		}
	}
</script>
<style>
	.el-upload__input {
		display: none !important;
	}

	.el-upload-dragger {
		width: 512px !important;
	}

	.el-upload__tip {
		color: red !important;
	}

	.el-upload__status_tip {
		margin-top: 10px;
		max-height: 300px;
		overflow: auto;
	}

	.el-upload {
		width: 125px !important;
	}
</style>