<template>
    <div v-if="show">
        <el-dialog :title="title" :visible.sync="show" :before-close="handleClose" :size="size" @close="close" class="new_dialog" :close-on-click-modal="!modal">
            <child v-on:callback="callback" :data="data"></child>
            <span slot="footer" class="dialog-footer" v-if="showFooter">
                <el-button @click="btnCancel">取 消</el-button>
                <el-button type="primary" @click="btnOk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                modal: true,
                close_on_press_escape: false,
                show_close: true,
                show: true,
                title: '',
                size: 'small',
                showFooter: false,
                data: {},
                customCallback: e => { }
            };
        },
        methods: {
            handleClose(done) {
                done();
            },
            close() {
                this.show = false;
                //this.customCallback({ type: 'close', $el: this, close: this.close, data: {} });
            },
            callback({ type, data }) { 
                if (!type || type == 'close' || type == 'cancel')
                    this.show && (this.show = false);

                this.customCallback({ type: type, $el: this, close: this.close, data: data });
            },
            btnOk() {
                this.customCallback({ type: 'ok', $el: this, close: this.close, data: {} });
            },
            btnCancel() {
                this.show = false;
                this.customCallback({ type: 'cancel', $el: this, close: this.close, data: {} });
            },
            then(func) {
                if (typeof func == 'function')
                    this.customCallback = func;
            }
        },
    }
</script>
<style>
    .new_dialog>.el-dialog--small {
        width: auto !important;
    }
</style>